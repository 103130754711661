import { Box, Flex, Heading, HStack, Icon, Link, Text, Tooltip, VStack, Wrap } from "@chakra-ui/react";
import React, { FC } from "react";
import { IoLogoDocker, IoLogoGithub, IoLogoJavascript, IoLogoLinkedin, IoLogoNodejs, IoLogoPython, IoLogoReact } from "react-icons/io5";
import { FaJava, FaAws } from "react-icons/fa";
import { SiDjango, SiRedis, SiPostgresql, SiMysql, SiSpring } from "react-icons/si";


const socialLinksNode = () => {
  return (
    <VStack spacing={4} align="left">
        <Text>Connect with me @</Text>
      <Box d="flex" alignItems="center">
        <HStack spacing={4}>
          <Link
            py={2}
            px={4}
            href="https://github.com/mtShaikh"
            rounded="md"
            bg="#333"
            color="#fff"
            fontWeight="bold"
            isExternal
          >
            <HStack spacing={2} alignItems="center">
              <Box as={IoLogoGithub} /> <Text>Github</Text>
            </HStack>
          </Link>
          <Link
            py={2}
            px={4}
            href="https://www.linkedin.com/in/mtshaikh/"
            rounded="md"
            bg="#0e76a8"
            color="#fff"
            fontWeight="bold"
            isExternal
          >
            <HStack spacing={2} alignItems="center">
              <Box as={IoLogoLinkedin} /> <Text>LinkedIn</Text>
            </HStack>
          </Link>
        </HStack>
      </Box>
    </VStack>
  );
};

const technologiesNode = () => {
  return (
    <Wrap mt="50px" mx="20px" spacing="24px" alignItems="center"
    justify="center" shouldWrapChildren>
        <Icon as={IoLogoPython} w="40px" h="40px"/>
      <Icon as={FaJava} w="40px" h="40px"/>
      <Icon as={IoLogoJavascript} w="40px" h="40px"/>
      <Icon as={IoLogoReact} w="40px" h="40px"/>
      <Icon as={SiDjango} w="40px" h="40px"/>
      <Icon as={FaAws} w="40px" h="40px"/>
      <Icon as={IoLogoDocker} w="40px" h="40px"/>
      <Icon as={IoLogoNodejs} w="40px" h="40px"/>
      <Icon as={SiRedis} w="40px" h="40px"/>
      <Icon as={SiPostgresql} w="40px" h="40px"/>
      <Icon as={SiMysql} w="40px" h="40px"/>
      <Icon as={SiSpring} w="40px" h="40px"/>
</Wrap>
  );
};

const Jumbotron: FC = () => {
  return (
    <VStack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p={[4, 5, 8, 12]}
    >
      <Box>
        <Heading as="h1" fontFamily="body" bgColor="blue.100" bgClip="text" fontSize="5xl">
          taha shaikh
        </Heading>
      </Box>
      <Flex flexDirection="column" >
      <Box>
        <VStack spacing={3}>
        <Box>
          <Box as="b" mr="3" fontSize="2xl">Engineer.</Box>
          <Box as="b" mr="3" fontSize="2xl">Architect.</Box>
        </Box>
        <Text fontSize="lg">
          Loves to solve problems.
        </Text>
        <Text fontSize="lg">Adept in the following technologies...</Text>
      </VStack>
      </Box>
        {technologiesNode()}
      </Flex>
      <Text fontSize="xl">And more...</Text>
      {socialLinksNode()}
    </VStack>
  );
};

export default Jumbotron;
